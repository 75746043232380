import { useEffect, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable";
import { Link } from "react-router-dom";
import config from "config";
import { Tooltip } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import Navbar from "examples/Navbars/index.js";
import { fetchBlocks } from "api/store_api.ts";
import { format, formatDistanceToNow } from "date-fns";
import MDInput from "components/MDInput";
import useDebounce from "hooks/useDebounce";
import useInterval from "hooks/useInterval";

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function Blocks() {
  const columns = [
    { Header: "no", accessor: "no", width: "5%", align: "left" },
    { Header: "block", accessor: "height", width: "5%", align: "left" },
    { Header: "txs", accessor: "txs", align: "center" },
    { Header: "hash", accessor: "hash", align: "center" },
    { Header: "validator", accessor: "proposer", align: "center" },
    { Header: "time", accessor: "time", align: "center" },
  ];

  const [originData, setOriginData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const debounced = useDebounce(tableParams.current, 500);

  const formatRows = (dataToFormat) => {
    const tmpRows = dataToFormat.map((row, index) => ({
      no: <span>{(tableParams.current - 1) * tableParams.pageSize + (index + 1)}</span>,
      height: (
        <Link className="link" to={generateBlockDetailPath(config.routes.blockDetail, row.height)}>
          {row.height}
        </Link>
      ),
      txs: <p>{row.txs}</p>,
      hash: <p>{row.hash}</p>,
      proposer: (
        <Link to={generateValidatorDetailPath(config.routes.validatorDetail, row.proposer)}>
          {row.proposer}
        </Link>
      ),
      time: row.time && (
        <Tooltip placement="bottom" title={format(row.time, "yyyy-MM-dd hh:mm:ss")}>
          <p>{formatDistanceToNow(row.time || "", { addSuffix: true })}</p>
        </Tooltip>
      ),
    }));

    setRows(tmpRows);
  };
  const fetchData = async () => {
    try {
      const response = await fetchBlocks(tableParams.current, tableParams.pageSize);
      if (response && response.data) {
        const tempData = response.data.map((data) => {
          return {
            key: data.height,
            ...data,
          };
        });

        setOriginData(tempData);
        setTableParams({
          ...tableParams,
          total: Math.ceil(response.total / tableParams.pageSize),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    formatRows(originData);
  }, [originData]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [tableParams.pageSize]);

  const handleTableChange = (currentPage) => {
    setTableParams({
      ...tableParams,
      current: currentPage,
    });
  };

  useEffect(() => {
    if (Number(debounced) < 1) {
      setTableParams({
        ...tableParams,
        current: 1,
      });
    } else if (Number(debounced) > tableParams.total) {
      setTableParams({
        ...tableParams,
        current: tableParams.total,
      });
    } else {
      setLoading(true);
      fetchData();
    }
  }, [debounced]);

  useInterval(() => {
    if (tableParams.current === 1) {
      fetchData();
    }
  }, 5000);

  return (
    <DashboardLayout>
      <Navbar />
      <Card>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            isLoading={loading}
          />
          <MDBox width="20rem" m={1} display="flex" gap={1}>
            <MDInput
              inputProps={{
                type: "number",
                min: 1,
                max: tableParams.total,
              }}
              value={tableParams.current}
              onChange={(value) => handleTableChange(value.target.value)}
            />
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <h6>Total pages: {tableParams.total}</h6>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Blocks;
