// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
export const convertVotingPower = (tokens) => {
  return Math.round(Number(tokens) / 10 ** 6).toLocaleString(undefined);
};

export const convertParticipation = (votingPercentage) => {
  return votingPercentage.toFixed(2) + "%";
};

export default function handler(req, res) {
  res.status(200).json({ name: "John Doe" });
}

export async function fetchValidators(page: number, perPage: number, options?: RequestInit) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/validators?page=${page}&size_of_page=${perPage}`,
      options
    );
    const { result } = await response.json();
    return result;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchLastBlock() {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/block/last`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchValidatorUptime(address: string, start: number, end: number) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/validator/${address}/uptime?start=${start}&end=${end}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchValidatorCommitSignatures(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/validator/${address}/commit_signatures`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchBlocks(page: number, perPage: number, options?: RequestInit) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/block?page=${page}&size_of_page=${perPage}`,
      options
    );
    const res = await response.json();
    const resutl = res.data.map((item, index) => {
      return {
        no: index + 1,
        height: item.header.height,
        hash: item.block_id,
        txs: item.tx_hashes.length,
        proposer: item.header.proposer_address,
        time: item.header.time,
      };
    });
    return {
      data: resutl,
      total: res.total,
    };
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchBlockDetail(height: string) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/block/height/${height}`);
    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchBlockByHash(hash: string) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/block/hash/${hash}`);
    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchTransactionDetail(hash: string) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tx/${hash}`);
    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchTransactions(page: number, perPage: number) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/tx?page=${page}&size_of_page=${perPage}`
    );
    const res = await response.json();
    const result = res.data.map((item, index) => {
      return {
        no: index + 1,
        blockId: item.block_id,
        tx: item.hash,
        height: undefined,
        type: item.tx && Object.keys(item.tx).length > 0 ? Object.keys(item.tx)[0] : item.tx_type,
        shielded: item.tx && item.tx.Transfer && item.tx.Transfer.shielded ? "Yes" : "No",
        status: item.return_code === 0 || item.tx_type === "Wrapper" ? "Success" : "Fail",
      };
    });
    return {
      data: result,
      total: res.total,
    };
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchProposals() {
  try {
    const response = await fetch(`${process.env.REACT_APP_IT_API_URL}/chain/governance/proposals`);
    const { proposals } = await response.json();

    const result = proposals?.map((p) => ({
      id: p.id,
      type: p.kind,
      author: p.author.Account,
      result: p.result,
      startEpoch: p.start_epoch,
      endEpoch: p.end_epoch,
      graceEpoch: p.grace_epoch,
      yesVotes: (
        (Number(p.yay_votes || 0) /
          (Number(p.yay_votes) + Number(p.nay_votes) + Number(p.abstain_votes) || 1)) *
        100
      ).toFixed(2),
      noVotes: (
        (Number(p.nay_votes || 0) /
          (Number(p.yay_votes) + Number(p.nay_votes) + Number(p.abstain_votes) || 1)) *
        100
      ).toFixed(2),
      abstainVotes: (
        (Number(p.abstain_votes || 0) /
          (Number(p.yay_votes) + Number(p.nay_votes) + Number(p.abstain_votes) || 1)) *
        100
      ).toFixed(2),
    }));

    result.sort((a, b) => b.id - a.id);

    return result;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchCurrentValidatorsList(options?: RequestInit) {
  try {
    const response = await fetch(`${process.env.REACT_APP_EXP_URL}/node/validators/list`, options);
    const data = await response.json();
    return data.currentValidatorsList;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function fetchLatestBlocks(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EXP_URL}/node/validators/validator/${address}/latestBlocks`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("An error occurred while fetching the data.", error);
    return null;
  }
}

export async function fetchLatestSignatures(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EXP_URL}/node/validators/validator/${address}/latestSignatures`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("An error occurred while fetching the data.", error);
    return null;
  }
}

export async function fetchValidatorsWithDetails(options?: RequestInit) {
  try {
    const response = await fetch(`${process.env.REACT_APP_GURU_URL}/validators`, options);

    const data = await response.json();

    if (!Array.isArray(data.validators)) {
      throw new Error("Invalid response format");
    }
    const dataFotmated = data.validators.map((validator) => {
      return {
        validator: validator.hex_address,
        votingPower: convertVotingPower(validator.tokens),
        participation: convertParticipation(validator.voting_power_percent),
        moniker: validator.moniker,
        operator: validator.operator_address,
        rank: validator.rank,
      };
    });

    return dataFotmated;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

export async function fetchValidator(hash) {
  try {
    const response = await fetch(`${process.env.REACT_APP_EXP_URL}/node/validators/list`);
    const data = await response.json();

    if (!Array.isArray(data.currentValidatorsList)) {
      throw new Error("Invalid response format");
    }

    for (const validator of data.currentValidatorsList) {
      if (validator.address === hash) {
        const uptimeData = await fetchValidatorUptime(
          validator.address,
          0, // Adjust the start and end values accordingly
          1000 // Just an example for end value, adjust it accordingly
        );
        const uptime = (uptimeData.uptime * 100).toFixed(2) + "%";

        const commitSignatures = await fetchValidatorCommitSignatures(validator.address);

        return {
          validator: validator.address,
          uptime: uptime,
          votingPower: convertVotingPower(validator.voting_power),
          participation: convertParticipation(validator.voting_percentage),
          commitSignatures: commitSignatures,
          moniker: validator.moniker,
          operator: validator.operator_address,
          type: validator.pub_key.type,
        };
      }
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
