import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/waves-light.svg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "examples/Navbars";
import MDTypography from "components/MDTypography";
import { Link, useParams } from "react-router-dom";
import { fetchTransactionDetail, fetchBlockByHash } from "api/store_api.ts";
import { CircularProgress } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import config from "config";
import MDBadge from "components/MDBadge";
import { formatDistanceToNow } from "date-fns";

function TransactionDetail() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [loading, setLoading] = useState(false);
  const [tranDetail, setTranDetail] = useState({
    hash: "",
    blockId: "",
    gasWanted: "0",
    gasUsed: "0",
    returnCode: 0,
    fee: 0,
    txType: "",
    data: "",
    tx: {
      Transfer: {
        shielded: "",
      },
    },
  });
  let { tx } = useParams();
  const [block, setBlock] = useState({
    chainId: "",
    height: "",
    hash: "",
    time: "",
    proposer: "",
  });

  const getTranDetail = async () => {
    try {
      setLoading(true);
      const resTx = await fetchTransactionDetail(tx);
      const txData = {
        hash: resTx.hash,
        blockId: resTx.block_id,
        gasWanted: "0",
        gasUsed: "0",
        returnCode: resTx.return_code,
        fee: resTx.fee_amount_per_gas_unit ? resTx.fee_amount_per_gas_unit : 0,
        data: resTx.data,
        tx:
          resTx.tx_type === "Decrypted" && resTx.tx && resTx.tx.Ibc
            ? {
                typeUrl: resTx.tx.Ibc.Any.type_url,
                value: [...resTx.tx.Ibc.Any.value.slice(0, 10), "..."],
              }
            : { ...resTx.tx },
        txType: resTx.tx_type,
      };
      setTranDetail(txData);
      getBlock(resTx.block_id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getBlock = async (blockId) => {
    const resBlock = await fetchBlockByHash(blockId);

    const block = {
      chainId: resBlock.header.chain_id,
      height: resBlock.header.height,
      hash: resBlock.block_id,
      time: resBlock?.header.time,
      proposer: resBlock.header.proposer_address,
    };

    setBlock(block);
  };

  useEffect(() => {
    setTranDetail({
      chainId: "",
      height: "",
      hash: "",
      time: "",
      proposer: "",
    });
    getTranDetail();
  }, [tx]);

  function generateBlockDetailPath(template, height) {
    let path = template;
    path = path.replace(`:height`, height);
    return path;
  }

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
            backgroundColor: "rgba(19,19,19, 1) !important",
          }}
        />
        <h2
          style={{
            position: "absolute",
            zIndex: "1000",
            color: "#fff",
            top: "90px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#efe43f",
          }}
        >
          TRANSACTION DETAIL
        </h2>
        <Card
          sx={{
            position: "relative",
            mt: -10,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox mt={5} mb={3}>
            {loading ? (
              <MDBox
                sx={{ height: "100px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </MDBox>
            ) : !tranDetail?.hash ? (
              <>Not found</>
            ) : (
              <Card sx={{ boxShadow: "none" }}>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Tx Hash:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {tranDetail?.hash}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Status:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDBadge
                        badgeContent={
                          tranDetail?.returnCode === 0 || tranDetail?.txType === "Wrapper"
                            ? "Success"
                            : "Failed"
                        }
                        color={
                          tranDetail?.returnCode === 0 || tranDetail?.txType === "Wrapper"
                            ? "success"
                            : "primary"
                        }
                        variant="gradient"
                        size="sm"
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Height:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        <Link
                          to={generateBlockDetailPath(config.routes.blockDetail, block?.height)}
                        >
                          {block?.height}
                        </Link>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Timestamp:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {block?.time && formatDistanceToNow(block?.time || "", { addSuffix: true })}{" "}
                        ({block?.time})
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Gas Price:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {tranDetail?.fee}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Shielded:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {tranDetail &&
                        tranDetail.tx &&
                        tranDetail.tx.Transfer &&
                        tranDetail.tx.Transfer.shielded
                          ? "Yes"
                          : "No"}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default TransactionDetail;
