import { combineReducers } from "redux";
import { LatestBlockInfo } from "./actions.ts";

const validatorLengthReducer = (state = 0, action) => {
  switch (action.type) {
    case "SET_VALIDATOR_LENGTH":
      return action.payload;
    default:
      return state;
  }
};

const latestBlockInfoReducer = (state: LatestBlockInfo | null = null, action: any) => {
  switch (action.type) {
    case "SET_LATEST_BLOCK_INFO":
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  validatorLength: validatorLengthReducer,
  latestBlockInfo: latestBlockInfoReducer,
});

export default rootReducer;
