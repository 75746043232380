const routes = {
  home: "/",
  validators: "/validators",
  governances: "/governances",
  validatorDetail: "/validators/:address",
  blocks: "/blocks",
  blockDetail: "/blocks/:height",
  transactions: "/transactions",
  transactionDetail: "/transactions/:tx",
};

export default routes;
