import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/waves-light.svg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "examples/Navbars";
import MDTypography from "components/MDTypography";
import { Link, useParams } from "react-router-dom";
import { fetchBlockDetail } from "api/store_api.ts";
import { CircularProgress } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import config from "config";

function BlockDetail() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [loading, setLoading] = useState(false);
  const [blockDetail, setBlockDetail] = useState({
    header: {
      height: "",
      proposer_address: "",
      time: "",
    },
    block_id: "",
    tx_hashes: [{ tx_type: "", hash_id: "" }],
  });
  let { height } = useParams();
  const columns = [
    { Header: "Transaction Type", accessor: "tx_type", align: "left" },
    { Header: "Hash Id", accessor: "hash_id", align: "center" },
  ];

  const [rows, setRows] = useState([]);
  const formatRows = (dataToFormat) => {
    const tmpRows = dataToFormat.map((row, index) => ({
      tx_type: <p>{row.tx_type}</p>,
      hash_id: (
        <Link
          className="link"
          to={generateTransDetailPath(config.routes.transactionDetail, row.hash_id)}
        >
          {row.hash_id}
        </Link>
      ),
    }));

    setRows(tmpRows);
  };

  function generateTransDetailPath(template, tx) {
    let path = template;
    path = path.replace(`:tx`, tx);
    return path;
  }

  function generateValidatorDetailPath(template, address) {
    let path = template;
    path = path.replace(`:address`, address);
    return path;
  }

  const getBlockDetail = async () => {
    try {
      setLoading(true);
      const response = await fetchBlockDetail(height);
      console.log("response: ", response);
      formatRows(response.tx_hashes);
      setBlockDetail(response || {});
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setBlockDetail({
      header: {
        height: "",
        proposer_address: "",
        time: "",
      },
      block_id: "",
      tx_hashes: [{ tx_type: "", hash_id: "" }],
    });
    getBlockDetail();
  }, [height]);

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
            backgroundColor: "rgba(19,19,19, 1) !important",
          }}
        />
        <h2
          style={{
            position: "absolute",
            zIndex: "1000",
            color: "#fff",
            top: "90px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#efe43f",
          }}
        >
          BLOCK DETAIL
        </h2>
        <Card
          sx={{
            position: "relative",
            mt: -10,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox mt={5} mb={3}>
            {loading ? (
              <MDBox
                sx={{ height: "100px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </MDBox>
            ) : !blockDetail?.block_id ? (
              <>Not found</>
            ) : (
              <>
                <Card sx={{ boxShadow: "none" }}>
                  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        Hash:
                      </MDTypography>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {blockDetail?.block_id}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        Proposer:
                      </MDTypography>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          <Link
                            to={generateValidatorDetailPath(
                              config.routes.validatorDetail,
                              blockDetail?.header.proposer_address
                            )}
                          >
                            {blockDetail?.header.proposer_address}
                          </Link>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        Height:
                      </MDTypography>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {blockDetail?.header?.height}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        No of Txs:
                      </MDTypography>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {blockDetail?.tx_hashes.length}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        Time:
                      </MDTypography>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {blockDetail?.header.time}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
                <DataTable
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                  entriesPerPage={false}
                />
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default BlockDetail;
