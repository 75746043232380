import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { useDispatch } from "react-redux";
import { fetchBlocks } from "api/store_api.ts";
import { setLatestBlockInfo } from "../../../../redux/actions.ts";
import useInterval from "hooks/useInterval";
import { Link } from "react-router-dom";
import config from "config";
import { Tooltip } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

function LatestBlocks() {
  const columns = [
    { Header: "no", accessor: "no", width: "5%", align: "left" },
    { Header: "BLOCK", accessor: "height", width: "5%", align: "left" },
    { Header: "Total Transactions", accessor: "txs", align: "center" },
    { Header: "HASH", accessor: "hash", align: "center" },
    { Header: "VALIDAROR", accessor: "proposer", align: "center" },
    { Header: "TIME", accessor: "time", align: "center" },
  ];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchBlocks(1, 10);
      if (response && response.data) {
        dispatch(
          setLatestBlockInfo({
            height: response.data[0].height,
            timestamp: response.data[0].time,
          })
        );

        const tmpRows = response.data.map((row, index) => ({
          no: <p>{index + 1}</p>,
          height: (
            <Link to={generateBlockDetailPath(config.routes.blockDetail, row.height)}>
              {row.height}
            </Link>
          ),
          txs: <p>{row.txs}</p>,
          hash: <p>{row.hash}</p>,
          proposer: (
            <Link to={generateValidatorDetailPath(config.routes.validatorDetail, row.proposer)}>
              {row.proposer}
            </Link>
          ),
          time: (
            <Tooltip placement="bottom" title={format(row.time, "yyyy-MM-dd hh:mm:ss")}>
              <p>{formatDistanceToNow(row.time || "", { addSuffix: true })}</p>
            </Tooltip>
          ),
        }));

        setRows(tmpRows);
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 5000);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Latest 10 Blocks
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          isLoading={loading}
        />
      </MDBox>
    </Card>
  );
}

export default LatestBlocks;
