import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/waves-light.svg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "examples/Navbars";
import MDTypography from "components/MDTypography";
import { useParams } from "react-router-dom";
import { fetchValidator } from "api/store_api.ts";
import { CircularProgress } from "@mui/material";

function ValidatorDetail() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [loading, setLoading] = useState(false);
  const [validatorDetail, setValidatorDetail] = useState({});
  let { address } = useParams();

  const getTransDetail = async () => {
    const response = await fetchValidator(address);
    console.log("response: ", response);
    setValidatorDetail(response);
    setLoading(false);
  };
  useEffect(() => {
    setValidatorDetail({});
    getTransDetail();
  }, [address]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
            backgroundColor: "rgba(19,19,19, 1) !important",
          }}
        />
        <h2
          style={{
            position: "absolute",
            zIndex: "1000",
            color: "#fff",
            top: "90px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#efe43f",
          }}
        >
          VALIDATOR DETAIL
        </h2>
        <Card
          sx={{
            position: "relative",
            mt: -10,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox mt={5} mb={3}>
            {loading ? (
              <MDBox
                sx={{ height: "100px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </MDBox>
            ) : !validatorDetail?.validator ? (
              <>Not found</>
            ) : (
              <Card sx={{ boxShadow: "none" }}>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Moniker:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.moniker}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Address:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.validator}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Voting power:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.votingPower}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Commit signatures:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.commitSignatures}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Participation:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.participation}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      Up time:
                    </MDTypography>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {validatorDetail?.uptime}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ValidatorDetail;
