import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "examples/Navbars";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import LatestTransactions from "./components/LatestTransactions";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import LatestBlocks from "./components/LatestBlocks";
import { useEffect, useState } from "react";
import { fetchValidatorsWithDetails } from "api/store_api.ts";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";

function Home() {
  const [totalValidator, setTotalValidator] = useState(0);
  const latestBlockInfo = useSelector((state) => state.latestBlockInfo);

  const getTotalValidator = async () => {
    try {
      const response = await fetchValidatorsWithDetails();
      if (response) {
        setTotalValidator(response.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTotalValidator();
  }, []);

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="dark"
                icon="account_balance"
                title="CHAIN ID"
                value="shielded-expedition.88f17d1d14"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon="schedule"
                title="Block Time"
                value={
                  latestBlockInfo?.timestamp ? (
                    format(latestBlockInfo?.timestamp, "yyyy-MM-dd hh:mm:ss")
                  ) : (
                    <CircularProgress color="secondary" />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="success"
                icon="view_comfy_alt"
                title="Block Height"
                value={
                  latestBlockInfo?.height ? (
                    latestBlockInfo?.height
                  ) : (
                    <CircularProgress color="secondary" />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="primary"
                icon="man"
                title="Validators Active"
                value={totalValidator}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <LatestTransactions />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <LatestBlocks />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Home;
