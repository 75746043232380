import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { useDispatch } from "react-redux";
import { fetchTransactions, fetchBlockByHash } from "api/store_api.ts";
import { setLatestBlockInfo } from "../../../../redux/actions.ts";
import useInterval from "hooks/useInterval";
import { Link } from "react-router-dom";
import config from "config";
import { Tooltip } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import MDBadge from "components/MDBadge/index.js";

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateTransactionDetailPath(template, tx) {
  let path = template;
  path = path.replace(`:tx`, tx);
  return path;
}

function LatestTransactions() {
  const columns = [
    { Header: "no", accessor: "no", width: "5%", align: "left" },
    { Header: "tx", accessor: "tx", width: "5%", align: "left" },
    { Header: "height", accessor: "height", align: "center" },
    { Header: "type", accessor: "type", align: "center" },
    { Header: "shielded", accessor: "shielded", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "time", accessor: "time", align: "center" },
  ];

  const [originData, setOriginData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetchTransactions(1, 10);
      if (response && response.data) {
        if (originData.length === 0) {
          setOriginData(response.data);
        } else {
          const itemToAdds = response.data.filter(
            (temp) => !originData.some((origin) => origin.tx === temp.tx)
          );
          if (itemToAdds?.length > 0) {
            setOriginData((prevData) => itemToAdds.concat(prevData).slice(1, 10));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getHeightOfTransaction = async () => {
    try {
      const validatorsWithDetails = await Promise.all(
        originData.map(async (tran) => {
          if (!tran.height) {
            const res = await fetchBlockByHash(tran.blockId);
            return {
              ...tran,
              height: res.header.height,
              time: res.header.time,
            };
          } else {
            return tran;
          }
        })
      );

      const tmpRows = validatorsWithDetails.map((row, index) => ({
        no: <p>{index + 1}</p>,
        tx: (
          <Link to={generateTransactionDetailPath(config.routes.transactionDetail, row.tx)}>
            {row.tx}
          </Link>
        ),
        height: (
          <Link to={generateBlockDetailPath(config.routes.blockDetail, row.height)}>
            {row.height}
          </Link>
        ),
        type: <p>{row.type}</p>,
        shielded: <p>{row.shielded}</p>,
        // status: <p className={row.status === "Success" ? "success" : "failed"}>{row.status}</p>,
        status: (
          <MDBadge
            badgeContent={row.status}
            color={row.status === "Success" ? "success" : "primary"}
            variant="gradient"
            size="sm"
          />
        ),
        time: row.time && (
          <Tooltip placement="bottom" title={format(row.time, "yyyy-MM-dd hh:mm:ss")}>
            <p>{formatDistanceToNow(row.time || "", { addSuffix: true })}</p>
          </Tooltip>
        ),
      }));

      setRows(tmpRows);
      setOriginData(validatorsWithDetails);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (originData.some((tran) => !tran.height)) {
      getHeightOfTransaction();
    }
  }, [originData]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 5000);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Latest 10 Transactions
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          isLoading={loading}
        />
      </MDBox>
    </Card>
  );
}

export default LatestTransactions;
