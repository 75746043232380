import Home from "layouts/home";

// @mui icons
import Icon from "@mui/material/Icon";
import Validators from "layouts/validators";
import Blocks from "layouts/blocks";
import Transactions from "layouts/transactions";
import Proposals from "layouts/proposal";
import ValidatorDetail from "layouts/validatorDetail";
import BlockDetail from "layouts/blockDetail";
import TransactionDetail from "layouts/transactionDetail";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Validators",
    key: "validators",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/validators",
    component: <Validators />,
  },
  {
    name: "ValidatorDetail",
    key: "validatorDetail",
    route: "/validators/:address",
    component: <ValidatorDetail />,
  },
  {
    type: "collapse",
    name: "Blocks",
    key: "blocks",
    icon: <Icon fontSize="small">view_comfy_alt</Icon>,
    route: "/blocks",
    component: <Blocks />,
  },
  {
    name: "BlockDetail",
    key: "blockDetail",
    route: "/blocks/:height",
    component: <BlockDetail />,
  },
  {
    type: "collapse",
    name: "Transaction",
    key: "transactions",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/transactions",
    component: <Transactions />,
  },
  {
    name: "TransactionDetail",
    key: "transactionDetail",
    route: "/transactions/:tx",
    component: <TransactionDetail />,
  },
  {
    type: "collapse",
    name: "Governances",
    key: "governances",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/governances",
    component: <Proposals />,
  },
];

export default routes;
