import { useEffect, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";
import { fetchValidatorsWithDetails, fetchBlockByHash } from "api/store_api.ts";
import useInterval from "hooks/useInterval";
import { Link } from "react-router-dom";
import config from "config";
import { Box } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import Navbar from "examples/Navbars/index.js";
import MDInput from "components/MDInput";
import useDebounce from "hooks/useDebounce";

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

function Validators() {
  const columns = [
    { Header: "rank", accessor: "rank", width: "5%", align: "left" },
    { Header: "validator", accessor: "validator", width: "5%", align: "left" },
    { Header: "moniker", accessor: "moniker", align: "center" },
    { Header: "operator", accessor: "operator", align: "center" },
    { Header: "VOTING POWER", accessor: "votingPower", align: "center" },
    { Header: "participation", accessor: "participation", align: "center" },
  ];

  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebounce(searchValue, 500);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatRows = (dataToFormat) => {
    const tmpRows = dataToFormat.map((row, index) => ({
      rank: <p>{row.rank}</p>,
      validator: (
        <Link
          className="link"
          to={generateValidatorDetailPath(config.routes.validatorDetail, row.validator)}
        >
          {row.validator}
        </Link>
      ),
      moniker: <p>{row.moniker}</p>,
      operator: <p>{row.operator}</p>,
      votingPower: <p>{row.votingPower}</p>,
      participation: <p>{row.participation}</p>,
    }));

    setRows(tmpRows);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchValidatorsWithDetails();
      if (response) {
        setOriginData(response);
        formatRows(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  useEffect(() => {
    formatRows(data);
  }, [data]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ validator, moniker }) =>
            validator?.toLowerCase().includes(normalizedSearchInput) ||
            moniker?.toLowerCase().includes(normalizedSearchInput)
        );

    setData(newData);
    // setTableParams((prevTableParams) => ({
    //   ...prevTableParams,
    //   pagination: {
    //     ...prevTableParams.pagination,
    //     total: newData.length,
    //   },
    // }));
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.startsWith(" ")) {
      return;
    }
    setSearchValue(searchValue);
  };

  return (
    <DashboardLayout>
      <Navbar />
      <Card>
        <MDBox>
          <Box sx={{ width: "100%", maxWidth: "600px" }}>
            <Box pr={1}>
              <MDInput
                placeholder="Search by validator/moniker"
                fullWidth
                border="1px"
                onChange={handleChange}
              />
            </Box>
          </Box>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            isLoading={loading}
          />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Validators;
