import { useEffect, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";

import DataTable from "examples/Tables/DataTable";
import { fetchProposals } from "api/store_api.ts";
import { Link } from "react-router-dom";
import config from "config";
import { Box } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import Navbar from "examples/Navbars/index.js";
import MDInput from "components/MDInput";
import useDebounce from "hooks/useDebounce";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function Proposals() {
  const columns = [
    { Header: "id", accessor: "id", width: "5%", align: "left" },
    { Header: "type", accessor: "type", width: "5%", align: "left" },
    { Header: "author", accessor: "author", align: "center" },
    { Header: "result", accessor: "result", align: "center" },
    { Header: "START EPOCH", accessor: "startEpoch", align: "center" },
    { Header: "END EPOCH", accessor: "endEpoch", align: "center" },
    { Header: "GRACE EPOCH", accessor: "graceEpoch", align: "center" },
    { Header: "YAY VOTES (YES)", accessor: "yesVotes", align: "center" },
    { Header: "NAY VOTES (NO)", accessor: "noVotes", align: "center" },
    { Header: "ABSTAIN VOTES (ABSTAIN)", accessor: "abstainVotes", align: "center" },
  ];

  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebounce(searchValue, 500);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
        mr={1}
      >
        {value}%
      </MDBox>
      <MDBox width="3rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  const formatRows = (dataToFormat) => {
    const tmpRows = dataToFormat.map((row) => ({
      id: <p>{row.id}</p>,
      type: (
        <MDBadge
          badgeContent={row?.type}
          color={
            row?.type === "Default"
              ? "success"
              : row?.type === "PgfSteward"
              ? "primary"
              : row.type === "DefaultWithWasm"
              ? "secondary"
              : ""
          }
          variant="gradient"
          size="lg"
        />
      ),
      author: <p>{row.author}</p>,
      result: (
        <MDBadge
          badgeContent={row?.result}
          color={
            row?.result === "Passed"
              ? "success"
              : row?.result === "Rejected"
              ? "primary"
              : "secondary"
          }
          variant="gradient"
          size="sm"
        />
      ),
      startEpoch: <p>{row.startEpoch}</p>,
      endEpoch: <p>{row.endEpoch}</p>,
      graceEpoch: <p>{row.graceEpoch}</p>,
      yesVotes: <Progress color="info" value={row.yesVotes} />,
      noVotes: <Progress color="info" value={row.noVotes} />,
      abstainVotes: <Progress color="info" value={row.abstainVotes} />,
    }));

    setRows(tmpRows);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchProposals();
      if (response) {
        setOriginData(response);
        formatRows(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  useEffect(() => {
    formatRows(data);
  }, [data]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(({ author }) => author?.toLowerCase().includes(normalizedSearchInput));

    setData(newData);
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.startsWith(" ")) {
      return;
    }
    setSearchValue(searchValue);
  };

  Progress.propTypes = {
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <DashboardLayout>
      <Navbar />
      <Card>
        <MDBox>
          <Box sx={{ width: "100%", maxWidth: "600px" }}>
            <Box pr={1}>
              <MDInput
                placeholder="Search by author"
                fullWidth
                border="1px"
                onChange={handleChange}
              />
            </Box>
          </Box>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            isLoading={loading}
          />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Proposals;
